import axios from "axios";
import config from "../helpers/config";
import instance from "../helpers/AxiosInterceptor";

export const uploadData = async (formData) => {
  let headers = { "Content-type": "multipart/form-data" };
  return await axios.post(`${config.baseURL}/inscription/upload`, formData, {
    headers: headers,
  });
};

export const downloadFile = async (filename) => {
  return await axios.get(
    `${config.baseURL}/inscription/load?filename=${filename}`
  );
};

export const find = (id) => {
  return instance.get(`/inscription/find/${id}`);
};

export const search = (query) => {
  console.log(query);
  return instance.post(`/inscription/search`, JSON.stringify(query));
};

export const findByNumero = (numero) => {
  return instance.get(`/inscription/findByNumero/${numero}`);
};

export const create = (request) => {
  return instance.post(`/inscription/create`, JSON.stringify(request));
};

export const update = (request) => {
  return instance.put(`/inscription/update`, JSON.stringify(request));
};

export const deleteRec = (id) => {
  return instance.delete(`/inscription/delete/${id}`);
};

export const deleteFile = (id) => {
  return instance.delete(`/inscription/delete/file/${id}`);
};

export const payerFrais = (request) => {
  const { inscriptionId, phoneNumber, amount } = request;
  return instance.post(
    `/payments/collect?phoneNumber=${phoneNumber}&amount=${amount}&inscriptionId=${inscriptionId}`
  );
};

export const payerCertificat = (request) => {
  const { inscriptionId, phoneNumber, amount } = request;
  return instance.post(
    `/payments/collectPaiementCert?phoneNumber=${phoneNumber}&amount=${amount}&inscriptionId=${inscriptionId}`
  );
};
export const downloadReportPayment = async (id) => {
  return instance.get(`/payments/downloadReportPayment/${id}`);
};
export const downloadReportInscription = async (id) => {
  return instance.get(`/payments/downloadReportInscription/${id}`);
};
export const generateExcelInscription = (query) => {
  console.log("excel query", query);
  return instance.post(
    `/inscription/generate/excelInscription`,
    JSON.stringify(query),
    {
      responseType: "blob",
    }
  );
};
export const generateExcelReport = () => {
  return instance.get(`/inscription/generate/excelReport`, {
    responseType: "blob",
  });
};
export const dashboard = async () => {
  return instance.get(`/inscription/dashboard`);
};

export const list = (page, size) => {
  return instance.get(`/inscription`, { params: { page, size } });
};

export const history = (id) => {
  return instance.get(`/inscription/history/${id}`);
};

export const historySms = (id) => {
  return instance.get(`/inscription/sms/${id}`);
};

export const sendSms = (id) => {
  console.log(id);
  return instance.post(`/inscription/sendSms/${id}`);
};
