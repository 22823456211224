import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Label,
  Container,
  Row,
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonGroup,
  Input,
} from "reactstrap";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import TableContainer from "../../components/Common/TableContainer";
import PaiementBloc from "./PaiementBloc";
import { useFormik } from "formik";
import { dataActions } from "../../saga/dataSlice";
import "../../assets/css/style.css";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PaimentCertBloc from "./PaimentCertBloc";
import { format, parse } from "date-fns";
const MySwal = withReactContent(Swal);

const Portail = () => {
  const { t } = useTranslation("translation");
  const { documentType, genre, situation, typeHandi, diplomes, connaiss } =
    useSelector((state) => state.data);

  const { isFetching } = useSelector((state) => state.inscription);
  const [formState, setForm] = useState(null);
  const [photoDocument, setPhotoDocument] = useState(null);
  const [openDropdown, setOpenDR] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { numero } = useParams();

  const navigate = useNavigate();

  const handleBackNavigation = () => {
    navigate(-1); // Navigates to the previous page
  };

  const hasRole = (role) => {
    const authUser = sessionStorage.getItem("authUser");
    const parsedAuthUser = JSON.parse(authUser);
    const decoded = jwtDecode(parsedAuthUser?.accessToken);
    const roles = decoded?.roles ?? [];
    console.log(roles);
    const found = [role]?.some((r) => roles?.indexOf(r) >= 0);
    return found;
  };

  const isCandidat = hasRole("ROLE_CANDIDAT");
  const isAdmin = hasRole("ROLE_ADMIN");

  useEffect(() => {
    dispatch(dataActions.loadData());
    setLoading(true);
    setTimeout(() => {
      loadInscription(numero);
      setLoading(false);
    }, 1500);
  }, []);

  const refresh = () => {
    loadInscription(numero);
  };

  const loadInscription = (num) => {
    let payload = {
      numero: num,
      onSuccess: (data) => {
        setForm({ ...data });
        const photoDoc =
          data?.documents ?? [].find((doc) => doc.type === "PHOTO");
        setPhotoDocument(photoDoc);
        setTimeout(() => {
          const selectedTypes = data.typeConnaissance
            ? data.typeConnaissance.split(",")
            : [];
          console.log(selectedTypes);
          selectedTypes.forEach((type) => {
            setFieldValue(`typeConnaissance.${type}`, true);
          });
        }, 1500);
      },
    };
    dispatch(inscriptionActions.findByNumero(payload));
  };

  const columns = [
    {
      Header: t("inscription.documentType"),
      accessor: (cellProps) => {
        const data =
          documentType.find((dt) => dt.code === cellProps.type)?.description ??
          "Reçu de paiement";
        return data;
      },
      disableFilters: true,
      filterable: false,
    },
    {
      Header: t("inscription.filename"),
      accessor: "filenameUser",
      disableFilters: true,
      filterable: false,
    },

    {
      Header: t("actions.title"),
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            <Link
              className="me-3 text-primary"
              onClick={() => {
                let payload = {
                  filename: cellProps.filename,
                  onSuccess: (response) => {
                    if (cellProps?.fileType == "application/pdf") {
                      const linkSource = `data:application/pdf;base64,${response}`;
                      const downloadLink = document.createElement("a");
                      downloadLink.href = linkSource;
                      downloadLink.download = cellProps?.filenameUser;
                      downloadLink.click();
                    } else {
                      var blob = new Blob(
                        [
                          Uint8Array.from(atob(response), (c) =>
                            c.charCodeAt(0)
                          ),
                        ],
                        { type: "application/octet-stream" }
                      );
                      var url = window.URL.createObjectURL(blob);
                      var a = document.createElement("a");
                      a.href = url;
                      a.download = cellProps.filenameUser;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  },
                };

                dispatch(inscriptionActions.downloadFile(payload));
              }}
            >
              <i className="ri-file-download-line"></i>
            </Link>
          </React.Fragment>
        );
      },
      disableFilters: true,
      filterable: false,
    },
  ];

  const formik = useFormik({
    initialValues: {
      ...formState,
      datenaiss: formState?.datenaiss
        ? format(
            parse(formState?.datenaiss, "dd/MM/yyyy", new Date()),
            "yyyy-MM-dd"
          )
        : "", // Convert backend format to browser-compatible format
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log(values);
      const typeConnaissanceString = values?.typeConnaissance
        ? Object.keys(values.typeConnaissance)
            .filter((key) => values.typeConnaissance[key])
            .join(",")
        : null;
      const modifiedValues = {
        ...values,
        typeConnaissance: typeConnaissanceString,
        datenaiss: format(new Date(values.datenaiss), "dd/MM/yyyy"),
      };
      console.log("modifiedValues", modifiedValues);
      let payload = {
        data: modifiedValues,
        onSuccess: (data) => {
          refresh();
          Swal.fire({
            icon: "success",
            title: "Modification réussie",
            text: "Les données ont été modifiées avec succès.",
            confirmButtonText: "OK",
          });
        },
      };

      dispatch(inscriptionActions.update(payload));
    },
  });
  console.log("Formik values:", formik.values);
  console.log("Formik isValid:", formik.isValid);

  const { setFieldValue, getFieldProps, values } = formik;

  useEffect(() => {}, [values.documents]);

  const handleSelectedFile = (event) => {
    const files = event.target.files;
    console.log("file", files[0]);
    let doc = {
      id: Math.floor(Math.random() * (600 - 99 + 1)) + 99,
      file: files[0],
      documentType: "RECU_PAIE",
    };

    uploadDoc(doc);
  };

  const handleAddFile = (doc) => {
    setFieldValue("documents", [...values.documents, doc]);
    loadInscription();
  };

  const uploadDoc = (doc) => {
    const query = new FormData();
    query.append("file", doc.file);
    query.append("inscriptionId", formState.id);
    query.append("documentType", doc.documentType);
    let payload = {
      formData: query,
      onSuccess: (data) => {
        handleAddFile(data);
      },
      onError: (error) => {
        console.log(error);
      },
    };
    dispatch(inscriptionActions.uploadFile(payload));
  };

  const hasRecu =
    values && values.documents?.some((doc) => doc.type === "RECU_PAIE");

  const getStripeClass = () => {
    switch (values?.paiement) {
      case "EN_ATTENTE":
        return "status-stripe status-pending";
      case "NON_PAYE":
        return "status-stripe status-failed";
      case "PAYE":
        return "status-stripe status-successful";
    }
  };

  const getStripeLabel = () => {
    switch (values?.paiement) {
      case "EN_ATTENTE":
        return "EN ATTENTE PAIEMENT";
      case "NON_PAYE":
        return "PAIEMENT ÉCHOUÉ";
      case "PAYE":
        return "PAIEMENT ÉFFECTUÉ";
    }
  };

  const handleCandidatureRetenueClick = () => {
    updateStatus("CANDIDATURE_RETENUE");
  };

  const handleNonRetenueClick = () => {
    updateStatus("CANDIDATURE_NON_RETENUE");
  };

  const handleAdmisClick = () => {
    if (!formik.values.admis || formik.values.admis !== "apte") {
      MySwal.fire({
        title:
          "Veuillez sélectionner 'APTE (Avis Direction)' avant de continuer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    MySwal.fire({
      title: t("message.confirm"),
      showCancelButton: true,
      confirmButtonText: t("actions.confirm"),
      cancelButtonText: t("actions.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus("CANDIDAT_ADMIS");
      } else if (result.isDismissed) {
      }
    });
  };

  const handleNonAdmisClick = () => {
    if (!formik.values.admis || formik.values.admis !== "inapte") {
      MySwal.fire({
        title:
          "Veuillez sélectionner 'INAPTE (Avis Direction)' avant de continuer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    MySwal.fire({
      title: t("message.confirm"),
      showCancelButton: true,
      confirmButtonText: t("actions.confirm"),
      cancelButtonText: t("actions.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus("CANDIDAT_NON_ADMIS");
      } else if (result.isDismissed) {
      }
    });
  };

  const updateStatus = (inscriptionStatus) => {
    console.log(inscriptionStatus);
    const updatedValues = { ...formState, inscriptionStatus };
    console.log("updatedValues", updatedValues);
    let payload = {
      data: updatedValues,
      onSuccess: (data) => {
        setForm({ ...data });
        refresh();
        Swal.fire(t("message.confirmSuccess"), "", "success");
      },
    };

    dispatch(inscriptionActions.update(payload));
  };

  const handleDownloadReportPaiment = (id) => {
    let payload = {
      id,
      onSuccess: (data) => {
        var byteCharacters = atob(data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: "application/pdf;base64" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      onError: () => {
        console.error("Error downloading payment report");
      },
    };
    dispatch(inscriptionActions.downloadReportPayment(payload));
  };

  const handleDownloadReportInscription = (id) => {
    let payload = {
      id,
      onSuccess: (data) => {
        var byteCharacters = atob(data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: "application/pdf;base64" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      onError: () => {
        console.error("Error downloading payment report");
      },
    };
    dispatch(inscriptionActions.downloadReportInscription(payload));
  };

  return (
    <React.Fragment>
      {isFetching || loading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid={true}>
            <AvForm
              className="needs-validation"
              onValidSubmit={formik.handleSubmit}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={2}>
                      {isCandidat ? (
                        <h5 className="mb-3">{values.numero}</h5>
                      ) : (
                        <h5
                          className="mb-3"
                          onClick={handleBackNavigation}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ri-arrow-go-back-line"></i>{" "}
                          {values.numero}
                        </h5>
                      )}
                    </Col>
                    <Col xs={10}>
                      <div className="d-flex justify-content-end align-items-center status">
                        <div className="progress-indicator d-flex align-items-center">
                          <div
                            className={`step ${
                              formState?.inscriptionStatus === "NOUVEAU"
                                ? "active"
                                : ""
                            }`}
                          >
                            NOUVEAU
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${
                              formState?.inscriptionStatus ===
                              "EN_COURS_ANALYSE"
                                ? "active"
                                : ""
                            }`}
                          >
                            EN COURS D'ANALYSE
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${
                              formState?.inscriptionStatus ===
                              "CANDIDATURE_RETENUE"
                                ? "active"
                                : ""
                            }`}
                          >
                            CANDIDATURE RETENUE
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${
                              formState?.inscriptionStatus ===
                              "CANDIDATURE_NON_RETENUE"
                                ? "active"
                                : ""
                            }`}
                          >
                            CANDIDATURE NON RETENUE
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${
                              formState?.inscriptionStatus === "CANDIDAT_ADMIS"
                                ? "active"
                                : ""
                            }`}
                          >
                            CANDIDAT ADMIS
                          </div>
                          <span className="arrow">→</span>
                          <div
                            className={`step ${
                              formState?.inscriptionStatus ===
                              "CANDIDAT_NON_ADMIS"
                                ? "active"
                                : ""
                            }`}
                          >
                            CANDIDAT NON ADMIS
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="payment-block">
                <Row className="">
                  <Col xs={2}>
                    <ButtonDropdown
                      isOpen={openDropdown}
                      toggle={() => setOpenDR(!openDropdown)}
                    >
                      <DropdownToggle caret color="light">
                        Télécharger <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          onClick={() =>
                            handleDownloadReportInscription(formState.id)
                          }
                        >
                          Fiche d'inscription
                        </DropdownItem>
                        {formState?.inscriptionStatus ===
                          "EN_COURS_ANALYSE" && (
                          <DropdownItem
                            onClick={() =>
                              handleDownloadReportPaiment(formState.id)
                            }
                          >
                            Reçu de paiement
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </Col>
                </Row>
                <CardBody>
                  <Row className="mb-5">
                    <Col xs={12}>
                      {isAdmin && (
                        <ButtonGroup>
                          {formState?.inscriptionStatus ===
                            "EN_COURS_ANALYSE" && (
                            <>
                              <Button onClick={handleCandidatureRetenueClick}>
                                Retenue
                              </Button>
                              <Button
                                className="ms-1"
                                onClick={handleNonRetenueClick}
                              >
                                Non Retenue
                              </Button>
                            </>
                          )}
                          {formState?.inscriptionStatus ===
                            "CANDIDATURE_RETENUE" &&
                            formState?.admis === "apte" && (
                              <>
                                <Button onClick={handleAdmisClick}>
                                  Admis
                                </Button>
                                <Button
                                  className="ms-1"
                                  onClick={handleNonAdmisClick}
                                >
                                  Non Admis
                                </Button>
                              </>
                            )}
                        </ButtonGroup>
                      )}
                    </Col>
                  </Row>

                  <div className={getStripeClass()}>{getStripeLabel()}</div>

                  <Row>
                    <Col xs={12}>
                      <h4 className=" mb-5 card-title">
                        {t("section.identification")}
                      </h4>
                      <Container fluid={true}>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.nom")}
                              </Label>
                              <AvField
                                {...getFieldProps("nom")}
                                placeholder={t("inscription.nom")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={
                                  isAdmin
                                    ? {} // Skip validation if the role is admin
                                    : { required: { value: true } } // Apply validation for other roles
                                }
                                id="code"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.prenom")}
                              </Label>
                              <AvField
                                {...getFieldProps("prenom")}
                                placeholder={t("inscription.prenom")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={
                                  isAdmin
                                    ? {} // Skip validation if the role is admin
                                    : { required: { value: true } } // Apply validation for other roles
                                }
                                id="prenom"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="pere">
                                {t("inscription.pere")}
                              </Label>
                              <AvField
                                {...getFieldProps("pere")}
                                placeholder={t("inscription.pere")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="pere"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="mere">
                                {t("inscription.mere")}
                              </Label>
                              <AvField
                                {...getFieldProps("mere")}
                                placeholder={t("inscription.mere")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="permeree"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.lieunais")}
                              </Label>
                              <AvField
                                {...getFieldProps("lieunais")}
                                placeholder={t("inscription.lieunais")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="lieunais"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.datenaiss")}
                              </Label>
                              <AvField
                                {...getFieldProps("datenaiss")}
                                placeholder={t("inscription.datenaiss")}
                                type="date"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="datenaiss"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.nationalite")}
                              </Label>
                              <AvField
                                {...getFieldProps("nationalite")}
                                placeholder={t("inscription.nationalite")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="nationalite"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="genre">
                                {t("inscription.genre")}
                              </Label>
                              <AvField
                                type="select"
                                className="form-control"
                                {...getFieldProps("genre")}
                                //onChange={handleChangeNationalite}
                                validate={{ required: { value: true } }}
                                id="genre"
                              >
                                <option value="">{t("Sélectionner...")}</option>
                                {genre.map((g, index) => (
                                  <option key={index} value={g.code}>
                                    {g.description}
                                  </option>
                                ))}
                              </AvField>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.numCmu")}
                              </Label>
                              <AvField
                                {...getFieldProps("numCmu")}
                                placeholder={t("inscription.numCmu")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="numCmu"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="numCNI">
                                {t("inscription.numCNI")}
                              </Label>
                              <AvField
                                {...getFieldProps("numCNI")}
                                placeholder={t("inscription.numCNI")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="numCNI"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="situation">
                                {t("inscription.situation")}
                              </Label>
                              <AvField
                                type="select"
                                className="form-control"
                                {...getFieldProps("situation")}
                                //onChange={handleChangeNationalite}
                                validate={{ required: { value: true } }}
                                id="situation"
                              >
                                <option value="">{t("Sélectionner...")}</option>
                                {situation.map((g, index) => (
                                  <option key={index} value={g.code}>
                                    {g.description}
                                  </option>
                                ))}
                              </AvField>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="typeHandi">
                                {t("inscription.typeHandi")}
                              </Label>
                              <AvField
                                type="select"
                                className="form-control"
                                {...getFieldProps("typeHandi")}
                                //onChange={handleChangeNationalite}
                                validate={{ required: { value: true } }}
                                id="typeHandi"
                              >
                                <option value="">{t("Sélectionner...")}</option>
                                {typeHandi.map((g, index) => (
                                  <option key={index} value={g.code}>
                                    {g.description}
                                  </option>
                                ))}
                              </AvField>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autreTypeHandicap"
                              >
                                {t("inscription.autreTypeHandicap")}
                              </Label>
                              <AvField
                                {...getFieldProps("autreTypeHandicap")}
                                placeholder={t("inscription.autreTypeHandicap")}
                                type="text"
                                className="form-control"
                                id="autreTypeHandicap"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.telephone")}
                              </Label>
                              <AvField
                                {...getFieldProps("telephone")}
                                placeholder={t("inscription.telephone")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  maxLength: {
                                    value: 10,
                                    errorMessage: t("message.phoneExceed"),
                                  },
                                }}
                                id="telephone"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <AvField
                                placeholder={t("inscription.email")}
                                errorMessage={t("message.emailInvalid")}
                                {...getFieldProps("email")}
                                label={t("inscription.email")}
                                type="email"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.lieuResidence")}
                              </Label>
                              <AvField
                                {...getFieldProps("lieuResidence")}
                                placeholder={t("inscription.lieuResidence")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="lieuResidence"
                              />
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <AvField
                                placeholder={t("inscription.niveauEtude")}
                                {...getFieldProps("niveauEtude")}
                                label={t("inscription.niveauEtude")}
                                type="text"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="typeDiplome"
                              >
                                {t("inscription.typeDiplome")}
                              </Label>
                              <AvField
                                type="select"
                                className="form-control"
                                {...getFieldProps("typeDiplome")}
                                //onChange={handleChangeNationalite}
                                validate={{ required: { value: true } }}
                                id="typeDiplome"
                              >
                                <option value="">{t("Sélectionner...")}</option>
                                {diplomes.map((g, index) => (
                                  <option key={index} value={g.code}>
                                    {g.description}
                                  </option>
                                ))}
                              </AvField>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="code">
                                {t("inscription.diplomeAutre")}
                              </Label>
                              <AvField
                                {...getFieldProps("diplomeAutre")}
                                placeholder={t("inscription.diplomeAutre")}
                                type="text"
                                errorMessage={t("message.required")}
                                className="form-control"
                                id="diplomeAutre"
                              />
                            </div>
                          </Col>
                          <Col md="8">
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="typeConnaissance"
                              >
                                {t("inscription.typeConnaissance")}
                              </Label>
                              <div
                                id="typeConnaissance"
                                className="form-control"
                              >
                                {connaiss.map((g, index) => (
                                  <div
                                    key={index}
                                    className="form-check form-check-inline me-5"
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`typeConnaissance_${index}`}
                                      value={g.code}
                                      checked={
                                        getFieldProps(
                                          `typeConnaissance.${g.code}`
                                        ).value
                                      }
                                      onChange={() => {
                                        setFieldValue(
                                          `typeConnaissance.${g.code}`,
                                          !getFieldProps(
                                            `typeConnaissance.${g.code}`
                                          ).value
                                        );
                                      }}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor={`typeConnaissance_${index}`}
                                    >
                                      {g.description}
                                    </Label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {isCandidat && (
                          <Row>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label className="form-label" htmlFor="code">
                                  {t("inscription.login")}
                                </Label>
                                <AvField
                                  {...getFieldProps("login")}
                                  placeholder={t("inscription.login")}
                                  type="text"
                                  errorMessage={t("message.required")}
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                    maxLength: {
                                      value: 10,
                                      errorMessage: t("message.phoneExceed"),
                                    },
                                  }}
                                  id="login"
                                />
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="mb-3">
                                <Label className="form-label" htmlFor="code">
                                  {t("inscription.password")}
                                </Label>
                                <AvField
                                  {...getFieldProps("password")}
                                  placeholder={t("inscription.password")}
                                  type="password"
                                  errorMessage={t("message.required")}
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  id="password"
                                />
                              </div>
                            </Col>
                          </Row>
                        )}

                        <Button
                          color="primary"
                          type="submit"
                          disabled={
                            formState?.inscriptionStatus ===
                              "EN_COURS_ANALYSE" && isCandidat
                          }
                        >
                          {t("actions.save")}
                        </Button>
                      </Container>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title">{t("section.documents")}</h4>
                      <TableContainer
                        columns={columns || []}
                        data={values.documents ?? []}
                        isPagination={false}
                        isAddParamList={true}
                        customPageSizeOptions={true}
                        iscustomPageSize={false}
                        isBordered={false}
                        customPageSize={10}
                        canDownloadtemp={true}
                        isGlobalFilter={false}
                        className="table-primary"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {!isCandidat &&
                [
                  "CANDIDATURE_RETENUE",
                  "CANDIDAT_ADMIS",
                  "CANDIDAT_NON_ADMIS",
                ].includes(formState?.inscriptionStatus) && (
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <CardBody>
                          <h4 className="card-title">{t("section.avis")}</h4>
                          <AvRadioGroup
                            inline
                            {...getFieldProps("admis")}
                            onChange={(e) =>
                              setFieldValue("admis", e.target.value)
                            } // Update Formik's value
                            disabled={
                              formState?.inscriptionStatus ===
                                "CANDIDAT_ADMIS" ||
                              formState?.inscriptionStatus ===
                                "CANDIDAT_NON_ADMIS"
                            }
                          >
                            <AvRadio
                              label={t("inscription.apte")}
                              value="apte"
                            />
                            <AvRadio
                              label={t("inscription.inapte")}
                              value="inapte"
                            />
                          </AvRadioGroup>
                          <AvField
                            {...getFieldProps("comment")}
                            label={t("inscription.comment")}
                            type="textarea"
                            rows="5"
                            disabled={
                              formState?.inscriptionStatus ===
                                "CANDIDAT_ADMIS" ||
                              formState?.inscriptionStatus ===
                                "CANDIDAT_NON_ADMIS"
                            }
                          />
                          <>
                            <Button
                              className="ms-1"
                              disabled={
                                formState?.inscriptionStatus ===
                                  "CANDIDAT_ADMIS" ||
                                formState?.inscriptionStatus ===
                                  "CANDIDAT_NON_ADMIS"
                              }
                              onClick={() => formik.handleSubmit()}
                            >
                              {t("actions.save")}
                            </Button>
                          </>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
            </AvForm>
            {(formState?.inscriptionStatus === "NOUVEAU" ||
              ["EN_ATTENTE", "NON_PAYE"].includes(values?.paiement)) &&
              isCandidat && (
                <Row>
                  <Col xs={12}>
                    <PaiementBloc formData={formState} onUpdate={refresh} />
                  </Col>
                </Row>
              )}
            {isCandidat &&<Row>
              <Col xs={12}>
                <PaimentCertBloc formData={formState} onUpdate={refresh} />
              </Col>
            </Row>}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default Portail;
