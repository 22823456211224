import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Container } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import Charts from "./charts";
import { FaFileExcel } from "react-icons/fa";
import Swal from "sweetalert2";

function Dashboard() {
  const { t } = useTranslation("translation");
  const [dashboardData, setDashboardData] = useState({});
  const [tableData, setTableData] = useState([]);
  const { isFetching } = useSelector((state) => state.inscription);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      onSuccess: (data) => {
        console.log("data", data);

        const sortedReportData = data.reportData.sort((a, b) => {
          if (a.type === null) return -1;
          if (b.type === null) return 1;
          return 0;
        });

        const totalRow = sortedReportData.find((row) => row.type === null);
        if (totalRow) {
          totalRow.type = "Total";
        }
        setTableData(sortedReportData);

        setDashboardData(data);
      },
      onError: (error) => {
        console.log(error);
      },
    };

    dispatch(inscriptionActions.dashboard(payload));
  }, [dispatch]);

  const columns = useMemo(
    () => [
      { Header: t("dashboard.type"), accessor: "type", disableFilters: true },
      {
        Header: t("dashboard.totalMale"),
        accessor: "totalMale",
        disableFilters: true,
      },
      {
        Header: t("dashboard.totalFemale"),
        accessor: "totalFemale",
        disableFilters: true,
      },
      {
        Header: t("dashboard.totalPerType"),
        accessor: "totalPerType",
        disableFilters: true,
      },
      {
        Header: t("dashboard.status1Count"),
        accessor: "status1Count",
        disableFilters: true,
      },
      {
        Header: t("dashboard.status2Count"),
        accessor: "status2Count",
        disableFilters: true,
      },
      {
        Header: t("dashboard.status3Count"),
        accessor: "status3Count",
        disableFilters: true,
      },
      {
        Header: t("dashboard.status4Count"),
        accessor: "status4Count",
        disableFilters: true,
      },
      {
        Header: t("dashboard.status5Count"),
        accessor: "status5Count",
        disableFilters: true,
      },
    ],
    [t]
  );

  const handleExportExcel = () => {
    const payload = {
      onSuccess: (data) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(data);
        link.download = "reportData.xlsx";
        link.click();

        Swal.fire({
          icon: "success",
          title: "Exportation réussie",
          text: "Le rapport Excel a été généré et téléchargé avec succès.",
          confirmButtonText: "Super !",
        });
      },
      onError: (error) => {
        console.error(error);

        Swal.fire({
          icon: "error",
          title: "Échec de l'exportation",
          text: `Une erreur s'est produite lors de la génération du rapport. Veuillez réessayer plus tard.
                 Erreur : ${error.message || "Erreur inconnue"}`,
          confirmButtonText: "Ok",
        });
      },
    };

    dispatch(inscriptionActions.generateExcelReport(payload));
  };

  const getRowClassName = (row) => {
    const isTotalRow = row.cells.some(
      (cell) => cell.value === "Total" && cell.row.id === "0"
    );
    return isTotalRow ? "total-row" : "";
  };

  return (
    <React.Fragment>
      {isFetching ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                <Charts
                  genreReport={dashboardData.genreReport}
                  typeHandiReport={dashboardData.typeHandiReport}
                  statusReport={dashboardData.statusReport}
                  genreStatusReport={dashboardData.genreStatusReport}
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="pagination-container">
                  <button
                    onClick={handleExportExcel}
                    color="primary"
                    style={{ margin: "0px 30px 10px 0px" }}
                    className="excel-button"
                  >
                    <FaFileExcel style={{ marginRight: "5px" }} />
                    {t("Excel")}
                  </button>
                </div>
                <TableContainer
                  columns={columns}
                  data={tableData}
                  sort={true}
                  isPagination={false}
                  customPageSizeOptions={true}
                  iscustomPageSize={false}
                  isBordered={false}
                  customPageSize={20}
                  getRowClassName={getRowClassName}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
}

export default Dashboard;
