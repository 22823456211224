import { createModule } from "saga-slice";
import { call, put } from "redux-saga/effects";
import {
  create,
  deleteFile,
  deleteRec,
  downloadFile,
  find,
  list,
  payerFrais,
  update,
  uploadData,
  downloadReportInscription,
  downloadReportPayment,
  dashboard,
  history,
  historySms,
  findByNumero,
  search,
  sendSms,
  generateExcelInscription,
  generateExcelReport,
  payerCertificat,
} from "../services/inscriptionService";

const inscriptionSlice = createModule({
  name: "inscription",
  initialState: {
    inscriptions: [],
    isFetching: false,
    error: null,
    totalElements: 0, // Total number of records
    totalPages: 0, // Total number of pages
    pageNumber: 0, // Current page number
    pageSize: 10, // Size of each page
  },
  reducers: {
    create: (state) => {
      state.isFetching = true;
    },
    list: (state) => {
      state.isFetching = true;
    },
    payerFrais: (state) => {
      state.isFetching = true;
    },
    payerCertificat: (state) => {
      state.isFetching = true;
    },
    find: (state) => {
      state.isFetching = true;
    },
    update: (state) => {
      state.isFetching = true;
    },
    findByNumero: (state) => {
      state.isFetching = true;
    },
    uploadFile: (state) => {
      state.isFetching = true;
    },
    delete: (state) => {
      state.isFetching = true;
    },
    search: (state) => {
      state.isFetching = true;
    },
    filter: (state) => {
      state.isFetching = false;
    },
    generateExcelInscription: (state) => {
      state.isFetching = true;
    },
    generateExcelReport: (state) => {
      state.isFetching = true;
    },
    deleteFile: (state) => {
      state.isFetching = true;
    },
    downloadFile: (state) => {
      state.isFetching = true;
    },
    downloadReportInscription: (state) => {
      state.isFetching = true;
    },
    downloadReportPayment: (state) => {
      state.isFetching = true;
    },
    dashboard: (state) => {
      state.isFetching = true;
    },
    history: (state) => {
      state.isFetching = true;
    },
    historySms: (state) => {
      state.isFetching = true;
    },
    sendSms: (state) => {
      state.isFetching = true;
    },
    fetchedData: (state, payload) => {
      state.inscriptions = payload.body.content; // Assuming content is in payload
      state.totalElements = payload.body.totalElements; // Assuming totalElements is in payload
      state.totalPages = payload.body.totalPages; // Assuming totalPages is in payload
      state.pageNumber = payload.body.pageNumber; // Upd
    },
    finishFetching: (state) => {
      state.isFetching = false;
    },
    fetchError: (state) => {
      state.isFetching = false;
      state.error = "An error occured";
    },
  },
  sagas: (A) => ({
    *[A.update]({ payload }) {
      try {
        const { data } = yield update(payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.payerFrais]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield payerFrais(payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data?.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
        yield call(payload.onError);
      }
    },
    *[A.payerCertificat]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield payerCertificat(payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data?.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
        yield call(payload.onError);
      }
    },
    *[A.uploadFile]({ payload }) {
      try {
        const { data } = yield uploadData(payload.formData);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data?.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.downloadFile]({ payload }) {
      try {
        const { data } = yield downloadFile(payload.filename);
        yield put(A.finishFetching());
        yield call(payload.onSuccess(data));
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.downloadReportInscription]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield downloadReportInscription(payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.downloadReportPayment]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield downloadReportPayment(payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.delete]({ payload }) {
      try {
        yield deleteRec(payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.deleteFile]({ payload }) {
      try {
        yield deleteFile(payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.create]({ payload }) {
      try {
        const { data } = yield create(payload.data);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data?.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
        yield call(payload.onError);
      }
    },
    *[A.find]({ payload }) {
      try {
        const { data } = yield find(payload.id);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.findByNumero]({ payload }) {
      try {
        const { data } = yield findByNumero(payload.numero);
        yield put(A.finishFetching());
        yield call(payload.onSuccess, data.body);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.search]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield search(payload);
        yield put(A.finishFetching());
        yield put(A.fetchedData(data));
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },

    *[A.filter]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield search(payload);
        yield put(A.finishFetching());
        yield put(A.fetchedData(data));
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.generateExcelInscription]({ payload }) {
      try {
        const { data } = yield generateExcelInscription(payload);
        console.log(data);
        yield put(A.finishFetching());
        if (payload.onSuccess) yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.generateExcelReport]({ payload }) {
      try {
        const { data } = yield generateExcelReport(payload);
        console.log(data);
        yield put(A.finishFetching());
        if (payload.onSuccess) yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.dashboard]({ payload }) {
      try {
        const { data } = yield dashboard();
        console.log(data);
        yield put(A.finishFetching());
        if (payload.onSuccess) {
          yield call(payload.onSuccess, data);
        }
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.history]({ payload }) {
      try {
        const { data } = yield history(payload.id);
        console.log(data);
        yield put(A.finishFetching());
        if (payload.onSuccess) {
          yield call(payload.onSuccess, data);
        }
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.historySms]({ payload }) {
      try {
        const { data } = yield historySms(payload.id);
        console.log(data);
        yield put(A.finishFetching());
        //yield put(A.fetchedData(data));
        if (payload.onSuccess) {
          yield call(payload.onSuccess, data);
        }
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.sendSms]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield sendSms(payload);
        yield put(A.finishFetching());
        //yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
  }),
});

export default inscriptionSlice;
export const inscriptionActions = inscriptionSlice.actions;
