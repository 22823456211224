import { AvField } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Label, Container, Row } from "reactstrap";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import TableContainer from "../../components/Common/TableContainer";
const ConfirmationTab = ({ formik }) => {
  const { t } = useTranslation("translation");
  const { getFieldProps, values } = formik;
  const { documentType, genre, situation, typeHandi, diplomes, connaiss } = useSelector((state) => state.data);

  const [documents, setDocuments] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (values.documents.length > 0) {
      setDocuments([...values.documents]);
    }
  }, [values.documents]);

  const columns = [
    {
      Header: t("inscription.documentType"),
      accessor: (cellProps) => {
        const data = documentType.find(
          (dt) => dt.code === cellProps.type
        )?.description;
        return data;
      },
      disableFilters: true,
      filterable: false,
    },
    {
      Header: t("inscription.filename"),
      accessor: "filenameUser",
      disableFilters: true,
      filterable: false,
    },

    {
      Header: t("actions.title"),
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            <Link
              className="me-3 text-primary"
              onClick={() => {
                let payload = {
                  filename: cellProps.filename,
                  onSuccess: (response) => {
                    if (cellProps?.fileType == "application/pdf") {
                      const linkSource = `data:application/pdf;base64,${response}`;
                      const downloadLink = document.createElement("a");
                      downloadLink.href = linkSource;
                      downloadLink.download = cellProps?.filenameUser;
                      downloadLink.click();
                    } else {
                      var blob = new Blob(
                        [
                          Uint8Array.from(atob(response), (c) =>
                            c.charCodeAt(0)
                          ),
                        ],
                        { type: "application/octet-stream" }
                      );
                      var url = window.URL.createObjectURL(blob);
                      var a = document.createElement("a");
                      a.href = url;
                      a.download = cellProps.filenameUser;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  },
                };

                dispatch(inscriptionActions.downloadFile(payload));
              }}
            >
              <i className="ri-file-download-line"></i>
            </Link>
          </React.Fragment>
        );
      },
      disableFilters: true,
      filterable: false,
    },
  ];

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">{t("section.identification")}</h4>
                <Container fluid={true}>
                  <Row>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.nom")}
                        </Label>
                        <AvField
                          {...getFieldProps("nom")}
                          placeholder={t("inscription.nom")}
                          type="text"
                          className="form-control"
                          id="code"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.prenom")}
                        </Label>
                        <AvField
                          {...getFieldProps("prenom")}
                          placeholder={t("inscription.prenom")}
                          type="text"
                          className="form-control"
                          id="prenom"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="pere">
                          {t("inscription.pere")}
                        </Label>
                        <AvField
                          {...getFieldProps("pere")}
                          placeholder={t("inscription.pere")}
                          type="text"
                          className="form-control"
                          id="pere"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="mere">
                          {t("inscription.mere")}
                        </Label>
                        <AvField
                          {...getFieldProps("mere")}
                          placeholder={t("inscription.mere")}
                          type="text"
                          disabled
                          className="form-control"
                          id="permeree"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.lieunais")}
                        </Label>
                        <AvField
                          {...getFieldProps("lieunais")}
                          placeholder={t("inscription.lieunais")}
                          type="text"
                          className="form-control"
                          disabled
                          id="lieunais"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.datenaiss")}
                        </Label>
                        <AvField
                          {...getFieldProps("datenaiss")}
                          placeholder={t("inscription.datenaiss")}
                          type="date"
                          disabled
                          className="form-control"
                          id="datenaiss"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.nationalite")}
                        </Label>
                        <AvField
                          {...getFieldProps("nationalite")}
                          placeholder={t("inscription.nationalite")}
                          type="text"
                          disabled
                          className="form-control"
                          id="nationalite"
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="genre">
                          {t("inscription.genre")}
                        </Label>
                        <AvField
                          type="select"
                          className="form-control"
                          {...getFieldProps("genre")}
                          disabled
                          id="genre"
                        >
                          <option value="">{t("Sélectionner...")}</option>
                          {genre.map((g, index) => (
                            <option key={index} value={g.code}>
                              {g.description}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.numCmu")}
                        </Label>
                        <AvField
                          {...getFieldProps("numCmu")}
                          placeholder={t("inscription.numCmu")}
                          type="text"
                          disabled
                          className="form-control"
                          id="numCmu"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="numCNI">
                          {t("inscription.numCNI")}
                        </Label>
                        <AvField
                          {...getFieldProps("numCNI")}
                          placeholder={t("inscription.numCNI")}
                          type="text"
                          errorMessage={t("message.required")}
                          className="form-control"
                          id="numCNI"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="situation">
                          {t("inscription.situation")}
                        </Label>
                        <AvField
                          type="select"
                          className="form-control"
                          {...getFieldProps("situation")}
                          disabled
                          id="situation"
                        >
                          <option value="">{t("Sélectionner...")}</option>
                          {situation.map((g, index) => (
                            <option key={index} value={g.code}>
                              {g.description}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="typeHandi">
                          {t("inscription.typeHandi")}
                        </Label>
                        <AvField
                          type="select"
                          className="form-control"
                          {...getFieldProps("typeHandi")}
                          disabled
                          id="typeHandi"
                        >
                          <option value="">{t("Sélectionner...")}</option>
                          {typeHandi.map((g, index) => (
                            <option key={index} value={g.code}>
                              {g.description}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="autreTypeHandicap"
                        >
                          {t("inscription.autreTypeHandicap")}
                        </Label>
                        <AvField
                          {...getFieldProps("autreTypeHandicap")}
                          placeholder={t("inscription.autreTypeHandicap")}
                          type="text"
                          className="form-control"
                          id="autreTypeHandicap"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.telephone")}
                        </Label>
                        <AvField
                          {...getFieldProps("telephone")}
                          placeholder={t("inscription.telephone")}
                          type="text"
                          className="form-control"
                          disabled
                          id="telephone"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.lieuResidence")}
                        </Label>
                        <AvField
                          {...getFieldProps("lieuResidence")}
                          placeholder={t("inscription.lieuResidence")}
                          type="text"
                          errorMessage={t("message.required")}
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="lieuResidence"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <div className="mb-3">
                        <AvField
                          placeholder={t("inscription.niveauEtude")}
                          {...getFieldProps("niveauEtude")}
                          label={t("inscription.niveauEtude")}
                          type="text"
                          disabled
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="typeDiplome">
                          {t("inscription.typeDiplome")}
                        </Label>
                        <AvField
                          type="select"
                          className="form-control"
                          {...getFieldProps("typeDiplome")}
                          disabled
                          id="typeDiplome"
                        >
                          <option value="">{t("Sélectionner...")}</option>
                          {diplomes.map((g, index) => (
                            <option key={index} value={g.code}>
                              {g.description}
                            </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.diplomeAutre")}
                        </Label>
                        <AvField
                          {...getFieldProps("diplomeAutre")}
                          placeholder={t("inscription.diplomeAutre")}
                          type="text"
                          disabled
                          className="form-control"
                          id="diplomeAutre"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="typeConnaissance">
                          {t("inscription.typeConnaissance")}
                        </Label>
                        <div id="typeConnaissance" className="form-control">
                          {connaiss.map((g, index) => (
                            <div
                              key={index}
                              className="form-check form-check-inline me-4"
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`typeConnaissance_${index}`}
                                value={g.code}
                                checked={(formik.values.typeConnaissance || "")
                                  .split(",")
                                  .includes(g.code)}
                                disabled
                              />
                              <Label
                                className="form-check-label"
                                htmlFor={`typeConnaissance_${index}`}
                              >
                                {g.description}
                              </Label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.login")}
                        </Label>
                        <AvField
                          {...getFieldProps("login")}
                          placeholder={t("inscription.login")}
                          type="text"
                          className="form-control"
                          disabled
                          id="login"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="code">
                          {t("inscription.password")}
                        </Label>
                        <AvField
                          {...getFieldProps("password")}
                          placeholder={t("inscription.password")}
                          type="text"
                          className="form-control"
                          id="password"
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">{t("section.documents")}</h4>
                <TableContainer
                  columns={columns || []}
                  data={documents ?? []}
                  isPagination={false}
                  isAddParamList={true}
                  customPageSizeOptions={true}
                  iscustomPageSize={false}
                  isBordered={false}
                  customPageSize={10}
                  canDownloadtemp={true}
                  isGlobalFilter={false}
                  className="table-primary"

                //handleDownloadTemp={() => downloadTemp()}
                //handleUpload={uploadData}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-check form-check-inline me-4" style={{color:"red"}}>
              <input
                type="checkbox"
                className="form-check-input"
                value="1"
                id="confirmed"
                checked={formik.values.confirmed === "1"}
                onChange={(e) => e.target.checked ? formik.setFieldValue('confirmed',e.target.value):formik.setFieldValue('confirmed',"")}
              />
              <Label className="form-check-label" htmlFor="confirmed">
                {t('inscription.confirmed')}
              </Label>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ConfirmationTab;
