import { AvField } from "availity-reactstrap-validation";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";

const DocumentTab = ({ formik }) => {
  const [file, setFile] = useState(null);
  const [docType, setDocType] = useState(null);
  const [documents, setDocuments] = useState([]);
  const { isFetching } = useSelector((state) => state.inscription);

  const { t } = useTranslation("translation");
  const { getFieldProps, values, setFieldValue } = formik;
  const { documentType } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  useEffect(() => {
    setDocuments([...values.documents]);
  }, [values.documents]);

  const handleSelectedFile = (event) => {
    const files = event.target.files;
    setFile(files[0]);
    let doc = {
      id: Math.floor(Math.random() * (600 - 99 + 1)) + 99,
      file: files[0],
      documentType: docType,
    };

    uploadDoc(doc);
  };

  const handleAddFile = (doc) => {
    setFieldValue("documents", [...values.documents, doc]);
  };

  const handleRemoveFile = (doc) => {
    const updated = values.documents.filter((d) => d.id !== doc.id);
    setFieldValue("documents", [...updated]);
  };

  const uploadDoc = (doc) => {
    const formData = new FormData();
    formData.append("file", doc.file);
    formData.append("inscriptionId", 0);
    formData.append("documentType", doc.documentType);
    let payload = {
      formData: formData,
      onSuccess: (data) => {
        handleAddFile(data);
        setDocType(null)
      },
      onError: (error) => {
        console.log(error);
      },
    };
    dispatch(inscriptionActions.uploadFile(payload));
  };

  const removeDoc = (doc) => {
    let payload = {
      id: doc.id,
      onSuccess: () => {
        handleRemoveFile(doc);
      },
      onError: (error) => {
        console.log(error);
      },
    };
    dispatch(inscriptionActions.deleteFile(payload));
  };

  const columns = [
    {
      Header: t("inscription.documentType"),
      accessor: (cellProps) => {
        const data = documentType.find(
          (dt) => dt.code === cellProps.type
        )?.description;
        return data;
      },
      disableFilters: true,
      filterable: false,
    },
    {
      Header: t("inscription.filename"),
      accessor: "filenameUser",
      disableFilters: true,
      filterable: false,
    },

    {
      Header: t("actions.title"),
      accessor: (cellProps) => {
        return (
          <React.Fragment>
            <Link
              className="me-3 text-primary"
              onClick={() => removeDoc(cellProps)}
            >
              <i className="ri-delete-bin-2-line"></i>
            </Link>
            <Link
              className="me-3 text-primary"
              onClick={() => {
                let payload = {
                  filename: cellProps.filename,
                  onSuccess: (response) => {
                    if (cellProps?.fileType == "application/pdf") {
                      const linkSource = `data:application/pdf;base64,${response}`;
                      const downloadLink = document.createElement("a");
                      downloadLink.href = linkSource;
                      downloadLink.download = cellProps?.filenameUser;
                      downloadLink.click();
                    } else {
                      var blob = new Blob(
                        [
                          Uint8Array.from(atob(response), (c) =>
                            c.charCodeAt(0)
                          ),
                        ],
                        { type: "application/octet-stream" }
                      );
                      var url = window.URL.createObjectURL(blob);
                      var a = document.createElement("a");
                      a.href = url;
                      a.download = cellProps.filenameUser;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }
                  },
                };

                dispatch(inscriptionActions.downloadFile(payload));
              }}
            >
              <i className="ri-file-download-line"></i>
            </Link>
          </React.Fragment>
        );
      },
      disableFilters: true,
      filterable: false,
    },
  ];

  return (
    <React.Fragment>
      {isFetching ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <Container fluid={true}>
          <Row>
            <Col md="2">
              <Label className="form-label" htmlFor="genre">
                {t("inscription.documentType")}
              </Label>
            </Col>
            <Col md="4">
              <AvField
                type="select"
                className="form-control"
                name="docType"
                onChange={(e) => {
                  setDocType(e.target.value || undefined);
                }}
                validate={{ required: { value: false } }}
                id="documentType"
              >
                <option value="">{t("Sélectionner...")}</option>
                {documentType.map((g, index) => (
                  <option key={index} value={g.code}>
                    {g.description}
                  </option>
                ))}
              </AvField>
            </Col>
          </Row>
          <Row>
            <Col sm="8">
              <div className="input-group">
                <input
                  accept="application/pdf, image/jpeg, image/png, image/gif, image/bmp"
                  type="file"
                  className="form-control"
                  id="customFile"
                  onChange={handleSelectedFile}
                  disabled={!docType}
                />
                {/* <div className="input-group mt-3">
                                {file && <small>{file.name}</small>}
                                {file && <Button className="mx-2" size="sm" type="button" color="danger" onClick={() => { setFile(null) }}><i class="ri-delete-bin-2-line"></i></Button>}
                                {file && <Button size="sm" type="button" color="info" onClick={() => {
                                    const url = window.URL.createObjectURL(file);
                                    if (file?.type == "application/pdf") {
                                        window.open(url, '_blank').focus();
                                    } else {
                                        const link = document.createElement("a");
                                        link.href = url;
                                        link.setAttribute(
                                            "download",
                                            `${file.name}`
                                        );
                                        document.body.appendChild(link);
                                        link.click();
                                    }
                                }}><i class="ri-file-download-line"></i></Button>}
                            </div> */}
              </div>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <TableContainer
              columns={columns || []}
              data={documents ?? []}
              isPagination={false}
              isAddParamList={true}
              customPageSizeOptions={true}
              iscustomPageSize={false}
              isBordered={false}
              customPageSize={10}
              canDownloadtemp={true}
              isGlobalFilter={false}
              className="table-primary"

              //handleDownloadTemp={() => downloadTemp()}
              //handleUpload={uploadData}
            />
          </Row>
        </Container>
      )}
    </React.Fragment>
  );
};
export default DocumentTab;
