// Front
import Layout from "./layout/reducer";

// Authentication Module
import Forget from "./auth/forgetpwd/reducer";
import { rootReducer } from "saga-slice";
import userSlice from "./auth/login/userSlice";
import inscriptionSlice from "../saga/inscriptionSlice";
import dataSlice from "../saga/dataSlice";
import transactionSlice from "../saga/transactionSlice";

const modules = [userSlice, inscriptionSlice, dataSlice, transactionSlice];

const appReducer = rootReducer(modules, {
  // public
  Layout,
  Forget,
});

export default appReducer;
