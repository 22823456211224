import React, { useState,useEffect } from 'react';
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import './styles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import instance from '../../helpers/AxiosInterceptor';
import config from '../../helpers/config';

const UserDetails = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [documents, setDocuments] = useState(['Document 1', 'Document 2']);
  const [newDocument, setNewDocument] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState(100);
  const [inscription,setInscription]=useState(null);
  const parseDateString = (dateString) => {
  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date object
};
 

  useEffect( () => {

     const getInscription = async (username) => {

    let response=null;
    let token = JSON.parse(sessionStorage.getItem("authUser")).accessToken;
    try {

      response = await instance.get(`${config.baseURL}/inscription/findby/${username}`,{
         headers:{
         Authorization: `Bearer ${token}`,
         "Content-Type": "application/json",
       }
      });
      
    } catch (error) {
      console.log(error);
      response = null;
    }
    return response?.data.body;
    
  };
   
    let data = getInscription(JSON.parse(sessionStorage.getItem("authUser"))?.username);
    setInscription(data);
     const fetchData = async () => {
      const username = JSON.parse(sessionStorage.getItem('authUser'))?.username;
      let data = await getInscription(username);
      if(data.datenaiss!= undefined){
        data.datenaiss = parseDateString(data.datenaiss)
      }
      else{
        data.datenaiss = null;
      }
      setInscription(data);
    };

    fetchData();


  }, []);

    useEffect(() => {
    console.log(inscription);
  }, [inscription]);


 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInscription({
      ...inscription,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setInscription({
      ...inscription,
      datenaiss: date
    });
  };


  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleAddDocument = () => {
    setDocuments([...documents, newDocument]);
    setNewDocument('');
  };

  const handlePaymentSubmit = (event) => {
    event.preventDefault();
    // handle payment logic here
  };

  return (
    <div style={{ paddingTop: '70px', paddingBottom: '50px',paddingLeft: '30px',paddingRight: '30px' }}>
    <Container >
      {/* First Container */}
      <Row className="row-custom">
        <Col>
          <h3>Actions</h3>
          <Button color="primary" onClick={toggleEdit}>Editer</Button>{' '}
          <Button color="success"  onClick={()=>{console.log(inscription);}} >Enregistrer </Button>
        </Col>
      </Row>

      {/* Second Container */}
      
      <Row className="row-custom">
        <Col>
          <h3>Profile</h3>
        </Col>
        <Col md="4">
          <img src="profile-image-url" alt="Profile" className="img-fluid" />
        </Col>
        <Col md="8">
          <AvForm>
            <AvField
              name="nom"
              label="Nom"
              type="text"
              disabled={!isEditable}
              required
            />
            <AvField
              name="prenom"
              label="Prenom"
              type="text"
              disabled={!isEditable}
              required
            />
            <AvField
              name="lieunais"
              label="Lieu de naissance"
              type="text"
              value={inscription?.lieunais||''}
              onChange={handleChange}
              disabled={!isEditable}
              required
            />
           
            <div className="form-group">
              <label>Date de naissance</label>
              <DatePicker
              selected={inscription?.datenaiss}
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              className="form-control"
              />
            </div>
            <AvField
              name="genre"
              label="Genre"
              type="select"
              value={inscription?.genre||''}
              onChange={handleChange}
              disabled={!isEditable}
              required
            >
             <option value='' >Select Gender</option>
            <option value="homme">Homme</option>
            <option value="femme">Femme</option>
            </AvField>

            <AvField
              name="nationalite"
              label="Nationalite"
              type="text"
              value={inscription?.nationalite||''}
              onChange={handleChange}
              disabled={!isEditable}
              required
            />
            <AvField
              name="situation"
              label="Situation"
              type="text"
              value={inscription?.situation||''}
              onChange={handleChange}
              disabled={!isEditable}
              required
            />
            <AvField
              name="nbrEnfants"
              label="Nombre d'enfants"
              type="text"
              value={inscription?.nbrEnfants||0}
              onChange={handleChange}
              disabled={!isEditable}
              required
            />
            <AvField
              name="lieuResidence"
              label="Lieu de résidence"
              type="text"
              value={inscription?.lieuResidence||''}
              onChange={handleChange}
              disabled={!isEditable}
              required
            />
            <AvField
              name="numCmu"
              label="Numero Cmu"
              type="text"
              value={inscription?.numCmu||''}
              onChange={handleChange}
              disabled={!isEditable}
              required
            />
            <AvField
              name="typeHandi"
              label="Type d'handicap"
              type="text"
              value={inscription?.typeHandi||''}
              onChange={handleChange}
              disabled={!isEditable}
              required
            />
            <AvField
              name="email"
              label="Email"
              type="email"
              disabled={!isEditable}
              required
            />
            {/* Add other fields as needed */}
          </AvForm>
        </Col>
      </Row>

      {/* Third Container */}
      <Row className="row-custom">
        <Col>
          <h3>Documents</h3>
          <ListGroup>
            {documents.map((doc, index) => (
              <ListGroupItem key={index}>{doc}</ListGroupItem>
            ))}
          </ListGroup>
          <Form inline className="mt-3" onSubmit={e => { e.preventDefault(); handleAddDocument(); }}>
            <FormGroup>
              <Label for="newDocument" className="mr-2">Ajouter un document:</Label>
              <Input
                type="text"
                id="newDocument"
                value={newDocument}
                onChange={e => setNewDocument(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" className="ml-2">Ajouter</Button>
          </Form>
        </Col>
      </Row>

      {/* Fourth Container */}
      <Row className="row-custom">
        <Col>
          <h3>Paiement</h3>
          <Form onSubmit={handlePaymentSubmit}>
            <FormGroup>
              <Label for="phoneNumber">Numero telephone</Label>
              <Input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount">Montant (CFA)</Label>
              <Input
                type="text"
                id="amount"
                value={amount}
                disabled
              />
            </FormGroup>
            <Button color="primary">Paiement</Button>
          </Form>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default UserDetails;