import { createModule } from "saga-slice";
import { call, put } from "redux-saga/effects";
import {
  generateExcelTransaction,
  search,
} from "../services/transactionService";

const transactionSlice = createModule({
  name: "transaction",
  initialState: {
    transactions: [],
    isFetching: false,
    error: null,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 10,
  },
  reducers: {
    generateExcelTransaction: (state) => {
      state.isFetching = true;
    },
    search: (state) => {
      state.isFetching = true;
    },
    fetchedData: (state, payload) => {
      state.totalElements = payload.body.totalElements;
      state.totalPages = payload.body.totalPages;
      state.pageNumber = payload.body.pageNumber;
      state.transactions = payload.body.content;
    },

    finishFetching: (state) => {
      state.isFetching = false;
    },

    fetchError: (state, action) => {
      state.isFetching = false;
      state.error = action.payload || "An error occurred";
    },
  },

  sagas: (A) => ({
    *[A.generateExcelTransaction]({ payload }) {
      try {
        const { data } = yield generateExcelTransaction(payload);
        console.log(data);
        yield put(A.finishFetching());
        if (payload.onSuccess) yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
    *[A.search]({ payload }) {
      try {
        console.log(payload);
        const { data } = yield search(payload);
        console.log(data);
        yield put(A.finishFetching());
        yield put(A.fetchedData(data));
        yield call(payload.onSuccess, data);
      } catch (e) {
        console.log(e);
        yield put(A.finishFetching());
        yield put(A.fetchError());
      }
    },
  }),
});

export default transactionSlice;
export const transactionActions = transactionSlice.actions;
