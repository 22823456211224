import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import appReducer from "./reducers";
import userSlice from "./auth/login/userSlice";
import { rootSaga } from "saga-slice";
import appSaga from "./sagas";
import inscriptionSlice from "../saga/inscriptionSlice";
import dataSlice from "../saga/dataSlice";
import transactionSlice from "../saga/transactionSlice";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const modules = [userSlice, inscriptionSlice, dataSlice, transactionSlice];

const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga(modules));
sagaMiddleware.run(appSaga);

export default store;
