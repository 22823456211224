import { AvField, AvForm } from "availity-reactstrap-validation";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import "toastr/build/toastr.min.css";
import { Client } from "@stomp/stompjs"; // Import the STOMP client
import SockJS from "sockjs-client"; // Import SockJS
const initForm = {
  phoneNumber: "",
  amount: "13000",
};
const MySwal = withReactContent(Swal);

const PaiementBloc = ({ formData, onUpdate }) => {
  const { operateurs } = useSelector((state) => state.data);
  const [formState, setForm] = useState(initForm);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [counter, setCounter] = useState(60);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { ...formState },
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        data: {
          inscriptionId: formData?.id,
          phoneNumber: values.phoneNumber,
          amount: values.amount,
        },
        onSuccess: () => {
          MySwal.fire({
            icon: "info",
            title: "Important",
            text: "Veuillez approuvé votre paiement MTN sur votre téléphone, puis cliquez sur OK",
            confirmButtonText: "OK",
            allowOutsideClick: false,

            //timer: 3000,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              let timerInterval;
              Swal.fire({
                title: "Vérification du paiement",
                html: "Veuillez patienter, nous vérifions votre paiement MTN après <b></b> seconds.",
                timer: 60000,
                timerProgressBar: true,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Math.floor(
                      Swal.getTimerLeft() / 1000
                    )}`;
                  }, 1000);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  checkPaiement();
                }
              });
            }
          });
        },
        onError: (errorMessage) => {
          MySwal.fire({
            icon: "error",
            title: "Transaction échoué",
            text: errorMessage,
            confirmButtonText: "Réessayez",
          });
        },
      };
      dispatch(inscriptionActions.payerFrais(payload));
    },
  });

  const { getFieldProps, setFieldValue, values } = formik;

  const handleChangeVille = ({ target }) => {
    setFieldValue(
      "operateur",
      operateurs.find((d) => d.code === target.value)?.code
    );
  };

  const checkPaiement = () => {
    dispatch(inscriptionActions.sendSms(formData.id));
    onUpdate();
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title">{t("section.payment")}</h4>
        <React.Fragment>
          <AvForm
            className="needs-validation"
            onValidSubmit={formik.handleSubmit}
          >
            <Container fluid={true}>
              {formData?.paiement === "EN_ATTENTE" && (
                <Row>
                  <Col lg="12">
                    Si vous avez déjà effectué votre paiement, cliquer sur
                    Rafraichir pour vérifier votre transaction avec MTN.
                    <Button
                      size="sm"
                      color="warning"
                      title="Rafraichir"
                      type="submit"
                      onClick={checkPaiement}
                    >
                      <i
                        className="ri-history-line"
                        style={{ fontSize: "16px" }}
                      ></i>
                    </Button>
                  </Col>
                </Row>
              )}
              {formData?.inscriptionStatus === "NOUVEAU" && (
                <Row>
                  <Col lg="12">
                    {formData?.paiement === "NON_PAYE" && (
                      <Label className="form-label" style={{ color: "red" }}>
                        {t("inscription.paieEchoue")}
                      </Label>
                    )}
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="code">
                        {t("inscription.operateur")}
                      </Label>

                      <select
                        required
                        className="form-control"
                        id="ville"
                        {...getFieldProps("operateur")}
                        onChange={handleChangeVille}
                      >
                        <option>Sélectionner...</option>
                        {operateurs.map((option, index) => (
                          <option key={index} value={option.code} selected>
                            {option.description}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="code">
                        {t("inscription.numeropay")}
                      </Label>
                      <AvField
                        {...getFieldProps("phoneNumber")}
                        placeholder={t("inscription.numeropay")}
                        type="text"
                        errorMessage={t("message.required")}
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="numeropay"
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="code">
                        {t("inscription.amount")}
                      </Label>
                      <AvField
                        {...getFieldProps("amount")}
                        placeholder={t("inscription.amount")}
                        type="text"
                        value="13000"
                        disabled
                        errorMessage={t("message.required")}
                        className="form-control"
                        id="amount"
                      />
                    </div>
                  </Col>
                </Row>
              )}

              <Button
                color="primary"
                type="submit"
                disabled={formData?.inscriptionStatus === "EN_COURS_ANALYSE"}
              >
                Payer
              </Button>
            </Container>
          </AvForm>
        </React.Fragment>
      </CardBody>
    </Card>
  );
};

export default PaiementBloc;
