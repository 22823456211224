import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { inscriptionActions } from "../../saga/inscriptionSlice";
import IdentificationTab from "./IdentificationTab";
import { dataActions } from "../../saga/dataSlice";
import DocumentTab from "./DocumentTab";
import { LOGIN_FORM } from "../../routes/routeConstants";
import ConfirmationTab from "./ConfirmationTab";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Swal from "sweetalert2";
import { format } from "date-fns";

const initForm = {
  nom: "",
  prenom: "",
  lieunais: "",
  datenaiss: "",
  genre: "",
  nationalite: "",
  situation: "",
  nbrEnfants: "",
  telephone: "",
  lieuResidence: "",
  numCmu: "",
  typeHandi: "",
  photo: "",
  numCNI: "",
  email: "",
  pere: "",
  mere: "",
  autreTypeHandicap: "",
  documents: [],
  login: null,
  password: null,
};

const Inscription = () => {
  const [activeTab, setActive] = useState(1);
  const [progressValue, setProgressValue] = useState(25);
  const [formState, setForm] = useState(initForm);
  const { isFetching } = useSelector((state) => state.inscription);

  const { t } = useTranslation("translation");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(dataActions.loadData());
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActive(tab);

        if (tab === 1) {
          setProgressValue(25);
        }
        if (tab === 2) {
          setProgressValue(50);
        }
        if (tab === 3) {
          setProgressValue(100);
        }
      }
    }
  };

  const showToast = (toastType, message, title) => {
    // toastr.options = {
    //     positionClass: positionClass,
    //     timeOut: timeOut,
    //     extendedTimeOut: extendedTimeOut,
    //     closeButton: closeButton,
    //     debug: debug,
    //     progressBar: progressBar,
    //     preventDuplicates: preventDuplicates,
    //     newestOnTop: newestOnTop,
    //     showEasing: showEasing,
    //     hideEasing: hideEasing,
    //     showMethod: showMethod,
    //     hideMethod: hideMethod,
    //     showDuration: showDuration,
    //     hideDuration: hideDuration
    // }

    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  };

  const formik = useFormik({
    initialValues: { ...formState },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.datenaiss) {
        values.datenaiss = format(new Date(values.datenaiss), "dd/MM/yyyy");
      }
      let payload = {
        data: values,
        onSuccess: () => {
          Swal.fire(t("message.saveSucces"), "", "success");
          navigate(LOGIN_FORM);
        },
        onError: () => {
          showToast("error", t("message.saveErreur"), "Erreur");
        },
      };
      if (values.id > 0) {
        dispatch(inscriptionActions.update(payload));
      } else {
        dispatch(inscriptionActions.create(payload));
      }
    },
  });

  const isIdentifiantValid =
    formik.values.login?.length === 10 && /^\d{10}$/.test(formik.values.login);
  const isPasswordValid =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      formik.values.password
    );
  const isPhoneValid =
    formik.values.telephone?.length === 10 &&
    /^\d{10}$/.test(formik.values.telephone);

  const isNextDisabled =
    !isIdentifiantValid || !isPasswordValid || !isPhoneValid;

  const isConfirmedDisabled = formik.values.confirmed !== "1";

  return (
    <React.Fragment>
      {isFetching ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
      ) : (
        <Container fluid={true}>
          <div className="entete-bg">
            {/* <div className="bg-overlay"></div> */}
          </div>
          <div className="notice-bg">
            <p>
              ATTENTION ! TOUTE FAUSSE DECLRATION ENTRAINERA LE REJET
              SYSTEMATIQUE DE LA CANDIDATURE SANS PREJUDICE DE POURSUITES
              JUDICIAIRES.
            </p>
          </div>
          <div id="progrss-wizard" className="twitter-bs-wizard">
            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => {
                    toggleTab(1);
                  }}
                  disabled={isNextDisabled}
                >
                  <span className="step-number">01</span>
                  <span className="step-title">
                    {t("section.identification")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => {
                    if (!isNextDisabled) toggleTab(2);
                  }}
                  disabled={isNextDisabled}
                >
                  <span className="step-number">02</span>
                  <span className="step-title">{t("section.documents")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 3 })}
                  onClick={() => {
                    if (!isNextDisabled) toggleTab(3);
                  }}
                  disabled={isNextDisabled}
                >
                  <span className="step-number">03</span>
                  <span className="step-title">
                    {t("section.confirmation")}
                  </span>
                </NavLink>
              </NavItem>
            </ul>

            <div id="bar" className="mt-4">
              <Progress
                color="success"
                striped
                animated
                value={progressValue}
              />
            </div>
            <AvForm
              className="needs-validation"
              onValidSubmit={formik.handleSubmit}
            >
              <TabContent
                activeTab={activeTab}
                className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={1}>
                  <IdentificationTab formik={formik} />
                </TabPane>
                <TabPane tabId={2}>
                  <DocumentTab formik={formik} />
                </TabPane>

                <TabPane tabId={3}>
                  <ConfirmationTab formik={formik} />
                </TabPane>
              </TabContent>
            </AvForm>
            <ul className="pager wizard twitter-bs-wizard-pager-link p-2">
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    if (activeTab === 1) {
                      navigate(LOGIN_FORM);
                    } else {
                      toggleTab(activeTab - 1);
                    }
                  }}
                >
                  Retour
                </Link>
              </li>
              <li
                className={
                  activeTab !== 3 || isConfirmedDisabled
                    ? "next disabled"
                    : "next"
                }
              >
                <Link
                  className="mx-2"
                  to="#"
                  onClick={() => {
                    if (!isConfirmedDisabled) formik.handleSubmit();
                  }}
                  disabled={isConfirmedDisabled}
                >
                  Enregistrer
                </Link>
              </li>
              <li
                className={
                  activeTab === 3 || isNextDisabled ? "next disabled" : "next"
                }
              >
                <Link
                  to="#"
                  onClick={() => {
                    if (!isNextDisabled) toggleTab(activeTab + 1);
                  }}
                  disabled={isNextDisabled}
                >
                  Suivant
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      )}
    </React.Fragment>
  );
};

export default Inscription;
